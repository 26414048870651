import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_CONSULTSNOTIFICATION,
    DELETE_CONSULTSNOTIFICATION,
    CONSULTSNOTIFICATION_FORM_TOOGLE_LOADING,
    SET_CONSULTSNOTIFICATION_LIST,
    SHOW_NOTIFICATION,
    UPDATE_CONSULTSNOTIFICATION,
} from "../constants";
import { formatConsultsNotifications } from "./settingsActionsUtils";

/* CONSULTSNOTIFICATION LIST */
export const fetchConsultsNotifications = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.consultsNotifications.list)
            .then((response) => {
                const consultsNotifications = formatConsultsNotifications(response.data);
                dispatch({
                    type: SET_CONSULTSNOTIFICATION_LIST,
                    payload: keyBy(consultsNotifications, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// CONSULTSNOTIFICATIONS
export const createConsultsNotification = (newConsultsNotification) => {
    return async (dispatch) => {
        dispatch({ type: CONSULTSNOTIFICATION_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.consultsNotifications.create, newConsultsNotification)
            .then((response) => {
                const consultsNotification = formatConsultsNotifications(response.data);
                dispatch({ type: ADD_NEW_CONSULTSNOTIFICATION, payload: consultsNotification });
                dispatch({ type: CONSULTSNOTIFICATION_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Notificacion creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: CONSULTSNOTIFICATION_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateConsultsNotification = (updatedConsultsNotification) => {
    return async (dispatch) => {
        dispatch({ type: CONSULTSNOTIFICATION_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.consultsNotifications.edit}${updatedConsultsNotification && updatedConsultsNotification._id}`, updatedConsultsNotification)
            .then((response) => {
                const consultsNotification = formatConsultsNotifications(response.data);
                dispatch({ type: UPDATE_CONSULTSNOTIFICATION, payload: consultsNotification });
                dispatch({ type: CONSULTSNOTIFICATION_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Notificacion actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: CONSULTSNOTIFICATION_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteConsultsNotifications = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: CONSULTSNOTIFICATION_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.consultsNotifications.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_CONSULTSNOTIFICATION, payload: Ids });
                dispatch({ type: CONSULTSNOTIFICATION_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Notificacion eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: CONSULTSNOTIFICATION_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
