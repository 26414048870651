import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_CLIENT,
    DELETE_CLIENT,
    CLIENT_FORM_TOOGLE_LOADING,
    SET_CLIENT_LIST,
    SHOW_NOTIFICATION,
    UPDATE_CLIENT,
} from "../constants";
import { formatClients } from "./settingsActionsUtils";

/* CLIENT LIST */
export const fetchClients = () => {
    return async (dispatch, useState) => {
        const response = await axios
            .get(API.clients.list)
            .then((response) => {
                const clients = formatClients(response.data, useState());
                dispatch({
                    type: SET_CLIENT_LIST,
                    payload: keyBy(clients, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// CLIENTS
export const createClient = (newClient) => {
    return async (dispatch) => {
        dispatch({ type: CLIENT_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.clients.create, newClient)
            .then((response) => {
                const client = formatClients(response.data);
                dispatch({ type: ADD_NEW_CLIENT, payload: client });
                dispatch({ type: CLIENT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Cliente creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: CLIENT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateClient = (updatedClient) => {
    return async (dispatch) => {
        dispatch({ type: CLIENT_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.clients.edit}${updatedClient && updatedClient._id}`, updatedClient)
            .then((response) => {
                const client = formatClients(response.data);
                dispatch({ type: UPDATE_CLIENT, payload: client });
                dispatch({ type: CLIENT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Cliente actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: CLIENT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteClients = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: CLIENT_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.clients.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_CLIENT, payload: Ids });
                dispatch({ type: CLIENT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Cliente eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: CLIENT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
