import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_TYPEMEDICALCONSULTATION,
    DELETE_TYPEMEDICALCONSULTATION,
    TYPEMEDICALCONSULTATION_FORM_TOOGLE_LOADING,
    SET_TYPEMEDICALCONSULTATION_LIST,
    SHOW_NOTIFICATION,
    UPDATE_TYPEMEDICALCONSULTATION,
} from "../constants";
import { formatTypeMedicalConsultations } from "./settingsActionsUtils";

/* TYPEMEDICALCONSULTATION LIST */
export const fetchTypeMedicalConsultations = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.typeMedicalConsultations.list)
            .then((response) => {
                const typeMedicalConsultations = formatTypeMedicalConsultations(response.data);
                dispatch({
                    type: SET_TYPEMEDICALCONSULTATION_LIST,
                    payload: keyBy(typeMedicalConsultations, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// TYPEMEDICALCONSULTATIONS
export const createTypeMedicalConsultation = (newTypeMedicalConsultation) => {
    return async (dispatch) => {
        dispatch({ type: TYPEMEDICALCONSULTATION_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.typeMedicalConsultations.create, newTypeMedicalConsultation)
            .then((response) => {
                const typeMedicalConsultation = formatTypeMedicalConsultations(response.data);
                dispatch({ type: ADD_NEW_TYPEMEDICALCONSULTATION, payload: typeMedicalConsultation });
                dispatch({ type: TYPEMEDICALCONSULTATION_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "TiposConsultasMedica creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: TYPEMEDICALCONSULTATION_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateTypeMedicalConsultation = (updatedTypeMedicalConsultation) => {
    return async (dispatch) => {
        dispatch({ type: TYPEMEDICALCONSULTATION_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.typeMedicalConsultations.edit}${updatedTypeMedicalConsultation && updatedTypeMedicalConsultation._id}`, updatedTypeMedicalConsultation)
            .then((response) => {
                const typeMedicalConsultation = formatTypeMedicalConsultations(response.data);
                dispatch({ type: UPDATE_TYPEMEDICALCONSULTATION, payload: typeMedicalConsultation });
                dispatch({ type: TYPEMEDICALCONSULTATION_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "TiposConsultasMedica actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: TYPEMEDICALCONSULTATION_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteTypeMedicalConsultations = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: TYPEMEDICALCONSULTATION_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.typeMedicalConsultations.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_TYPEMEDICALCONSULTATION, payload: Ids });
                dispatch({ type: TYPEMEDICALCONSULTATION_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "TiposConsultasMedica eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: TYPEMEDICALCONSULTATION_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
