import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_CONFIRMCONSULT,
    DELETE_CONFIRMCONSULT,
    CONFIRMCONSULT_FORM_TOOGLE_LOADING,
    SET_CONFIRMCONSULT_LIST,
    SHOW_NOTIFICATION,
    UPDATE_CONFIRMCONSULT,
} from "../constants";
import { formatConfirmConsults } from "./settingsActionsUtils";

/* CONFIRMCONSULT LIST */
export const fetchConfirmConsults = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.confirmConsults.list)
            .then((response) => {
                const confirmConsults = formatConfirmConsults(response.data);
                dispatch({
                    type: SET_CONFIRMCONSULT_LIST,
                    payload: keyBy(confirmConsults, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// CONFIRMCONSULTS
export const createConfirmConsult = (newConfirmConsult) => {
    return async (dispatch) => {
        dispatch({ type: CONFIRMCONSULT_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.confirmConsults.create, newConfirmConsult)
            .then((response) => {
                const confirmConsult = formatConfirmConsults(response.data);
                dispatch({ type: ADD_NEW_CONFIRMCONSULT, payload: confirmConsult });
                dispatch({ type: CONFIRMCONSULT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "ConfirmarCita creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: CONFIRMCONSULT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateConfirmConsult = (updatedConfirmConsult) => {
    return async (dispatch) => {
        dispatch({ type: CONFIRMCONSULT_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.confirmConsults.edit}${updatedConfirmConsult && updatedConfirmConsult._id}`, updatedConfirmConsult)
            .then((response) => {
                const confirmConsult = formatConfirmConsults(response.data);
                dispatch({ type: UPDATE_CONFIRMCONSULT, payload: confirmConsult });
                dispatch({ type: CONFIRMCONSULT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "ConfirmarCita actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: CONFIRMCONSULT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteConfirmConsults = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: CONFIRMCONSULT_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.confirmConsults.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_CONFIRMCONSULT, payload: Ids });
                dispatch({ type: CONFIRMCONSULT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "ConfirmarCita eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: CONFIRMCONSULT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
