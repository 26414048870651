import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_MEDICALHISTORY,
    DELETE_MEDICALHISTORY,
    MEDICALHISTORY_FORM_TOOGLE_LOADING,
    SET_MEDICALHISTORY_LIST,
    SHOW_NOTIFICATION,
    UPDATE_MEDICALHISTORY,
} from "../constants";
import { formatMedicalHistorys } from "./settingsActionsUtils";

/* MEDICALHISTORY LIST */
export const fetchMedicalHistorys = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.MedicalHistorys.list)
            .then((response) => {
                const MedicalHistorys = formatMedicalHistorys(response.data);
                dispatch({
                    type: SET_MEDICALHISTORY_LIST,
                    payload: keyBy(MedicalHistorys, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

export const fetchMedicalHistorysByUser = (user) => {
    return async (dispatch) => {
        const response = await axios
            .get(`${API.MedicalHistorys.byUser}?user=${user}`)
            .then((response) => {
                const medicalHistorys = formatMedicalHistorys(response.data);
                dispatch({
                    type: SET_MEDICALHISTORY_LIST,
                    payload: keyBy(medicalHistorys, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// MEDICALHISTORYS
export const createMedicalHistory = (newMedicalHistory) => {
    return async (dispatch) => {
        dispatch({ type: MEDICALHISTORY_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.MedicalHistorys.create, newMedicalHistory)
            .then((response) => {
                const MedicalHistory = formatMedicalHistorys(response.data);
                dispatch({ type: ADD_NEW_MEDICALHISTORY, payload: MedicalHistory });
                dispatch({ type: MEDICALHISTORY_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "HistorialMedico creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: MEDICALHISTORY_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateMedicalHistory = (updatedMedicalHistory) => {
    return async (dispatch) => {
        dispatch({ type: MEDICALHISTORY_FORM_TOOGLE_LOADING });

        const response = await axios
            .put(`${API.MedicalHistorys.edit}${updatedMedicalHistory && updatedMedicalHistory._id}`, updatedMedicalHistory)
            .then((response) => {
                const MedicalHistory = formatMedicalHistorys(response.data);
                dispatch({ type: UPDATE_MEDICALHISTORY, payload: MedicalHistory });
                dispatch({ type: MEDICALHISTORY_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "HistorialMedico actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: MEDICALHISTORY_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteMedicalHistorys = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: MEDICALHISTORY_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.MedicalHistorys.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_MEDICALHISTORY, payload: Ids });
                dispatch({ type: MEDICALHISTORY_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "HistorialMedico eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: MEDICALHISTORY_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const addMedicalHistoryNote = (medicalHistoryId, noteData) => {

    return async (dispatch) => {

        dispatch({ type: MEDICALHISTORY_FORM_TOOGLE_LOADING });
        try {

            const response = await axios.post(`${API.MedicalHistorys.addNotes.replace(':medicalHistoryId', medicalHistoryId)}`, noteData);

            const updatedConsultation = response.data;

            dispatch({ type: UPDATE_MEDICALHISTORY, payload: updatedConsultation });
            dispatch({ type: MEDICALHISTORY_FORM_TOOGLE_LOADING });
            dispatch({
                type: SHOW_NOTIFICATION,
                payload: {
                    show: true,
                    status: 'info',
                    message: 'Nota agregada al historial médico.',
                },
            });
            return response && response.status;
        } catch (error) {

            dispatch({ type: MEDICALHISTORY_FORM_TOOGLE_LOADING });

            dispatch({
                type: SHOW_NOTIFICATION,
                payload: {
                    show: true,
                    status: 'error',
                    message: 'Error al agregar la nota al historial médico.',
                },
            });

            return error;
        }
    };
};

export const modifiMedicalHisotryNote = (medicalHistoryId, noteIndex, noteData) => {
    return async (dispatch) => {

        dispatch({ type: MEDICALHISTORY_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.MedicalHistorys.editNotes.replace(':medicalHistoryId', medicalHistoryId).replace(':noteIndex', noteIndex)}`, noteData)
            .then((response) => {
                const updatedMedicalHistory = response.data.data.medicalHistory;
                dispatch({ type: UPDATE_MEDICALHISTORY, payload: updatedMedicalHistory });
                dispatch({ type: MEDICALHISTORY_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Nota actualizada en el historial médico.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: MEDICALHISTORY_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "error",
                        message: "Error al actualizar la nota en el historial médico.",
                    },
                });
                return err;
            });
        return response;
    };
};

export const deleteMedicalHistoryNote = (medicalHistoryId, noteIndex) => {
    return async (dispatch) => {
        dispatch({ type: MEDICALHISTORY_FORM_TOOGLE_LOADING });
        try {
            const response = await axios.delete(`${API.MedicalHistorys.deleteNotes.replace(':medicalHistoryId', medicalHistoryId).replace(':noteIndex', noteIndex)}`);

            dispatch({
                type: SHOW_NOTIFICATION,
                payload: {
                    show: true,
                    status: 'info',
                    message: 'Nota eliminada del historial médico.',
                },
            });
            return response && response.status;
        } catch (error) {

            dispatch({ type: MEDICALHISTORY_FORM_TOOGLE_LOADING });

            dispatch({
                type: SHOW_NOTIFICATION,
                payload: {
                    show: true,
                    status: 'error',
                    message: 'Error al eliminar la nota del historial médico.',
                },
            });

            return error;
        }
    };
};
