import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_EMPLOYEE,
    DELETE_EMPLOYEE,
    EMPLOYEE_FORM_TOOGLE_LOADING,
    SET_EMPLOYEE_LIST,
    SHOW_NOTIFICATION,
    UPDATE_EMPLOYEE,
} from "../constants";
import { formatEmployees } from "./settingsActionsUtils";

/* EMPLOYEE LIST */
export const fetchEmployees = () => {
    return async (dispatch, useState) => {
        const response = await axios
            .get(API.employees.list)
            .then((response) => {
                const employees = formatEmployees(response.data, useState());
                dispatch({
                    type: SET_EMPLOYEE_LIST,
                    payload: keyBy(employees, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// EMPLOYEES
export const createEmployee = (newEmployee) => {
    return async (dispatch) => {
        dispatch({ type: EMPLOYEE_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.employees.create, newEmployee)
            .then((response) => {
                const employee = formatEmployees(response.data);
                dispatch({ type: ADD_NEW_EMPLOYEE, payload: employee });
                dispatch({ type: EMPLOYEE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Trabajadores creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: EMPLOYEE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateEmployee = (updatedEmployee) => {
    return async (dispatch) => {
        dispatch({ type: EMPLOYEE_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.employees.edit}${updatedEmployee && updatedEmployee._id}`, updatedEmployee)
            .then((response) => {
                const employee = formatEmployees(response.data);
                dispatch({ type: UPDATE_EMPLOYEE, payload: employee });
                dispatch({ type: EMPLOYEE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Trabajadores actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: EMPLOYEE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteEmployees = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: EMPLOYEE_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.employees.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_EMPLOYEE, payload: Ids });
                dispatch({ type: EMPLOYEE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Trabajadores eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: EMPLOYEE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
