export const API = {
  consultsNotifications: {
    create: `/consultsNotification`,
    edit: `/consultsNotification/`,
    delete: `/consultsNotification/`,
    list: `/consultsNotification`,
  },
  room: {
    create: `/room`,
    edit: `/room/`,
    delete: `/room/`,
    list: `/room`,
  },
  MyConsults: {
    create: `/MyConsult`,
    edit: `/MyConsult/`,
    delete: `/MyConsult/`,
    list: `/MyConsult`,
  },
  confirmConsults: {
    create: `/confirmConsult`,
    edit: `/confirmConsult/`,
    delete: `/confirmConsult/`,
    list: `/confirmConsult`,
  },
  medicalAppointments: {
    create: `/medicalAppointment`,
    edit: `/medicalAppointment/`,
    delete: `/medicalAppointment/`,
    list: `/medicalAppointment`,
  },
  medicalConsultations: {
    create: `/medicalConsulation`,
    edit: `/medicalConsulation/`,
    delete: `/medicalConsulation/`,
    list: `/medicalConsulation`,
    byDate: `/medicalConsulation/date/`,
    fromDate: `/medicalConsulation/fromDate/`,
    byDoctor: `/medicalConsulation/doctor/`,
    byClient: `/medicalConsulation/client/`,
    byConfirm: `/medicalConsulation/confirm/`,
    addNotes: `/medicalConsulation/:consultationId/notes`,
    editNotes: `/medicalConsulation/:consultationId/note/:noteIndex`,
    deleteNotes: `/medicalConsulation/:consultationId/note/:noteIndex`,
    getMinutes: `/medicalConsulation/getMinutes`,
  },
  employees: {
    create: `/employee`,
    edit: `/employee/`,
    delete: `/employee/`,
    list: `/employee`,
  },
  typeMedicalConsultations: {
    create: `/typeMedicalConsultation`,
    edit: `/typeMedicalConsultation/`,
    delete: `/typeMedicalConsultation/`,
    list: `/typeMedicalConsultation`,
  },
  clients: {
    create: `/client`,
    edit: `/client/`,
    delete: `/client/`,
    list: `/client`,
  },
  MedicalHistorys: {
    create: `/MedicalHistory`,
    edit: `/MedicalHistory/`,
    delete: `/MedicalHistory/`,
    list: `/MedicalHistory`,
    byUser: `/MedicalHistory/user`,
    addNotes: `/MedicalHistory/:medicalHistoryId/notes`,
    editNotes: `/MedicalHistory/:medicalHistoryId/note/:noteIndex`,
    deleteNotes: `/MedicalHistory/:medicalHistoryId/note/:noteIndex`,
  },
  auth: {
    login: `/user/login`,
    check2FA: `/user/check2FA`,
  },
  users: {
    profile: `/user/profile`,
    create: `/user/register/`,
    edit: `/user/`,
    delete: `/user/`,
    list: `/user`,
  },
  alerts: {
    create: `/alert`,
    edit: `/alert/`,
    delete: `/alert/`,
    list: `/alert`,
  },
};
