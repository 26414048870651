import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_MYCONSULT,
    DELETE_MYCONSULT,
    MYCONSULT_FORM_TOOGLE_LOADING,
    SET_MYCONSULT_LIST,
    SHOW_NOTIFICATION,
    UPDATE_MYCONSULT,
} from "../constants";
import { formatMyConsults } from "./settingsActionsUtils";

/* MYCONSULT LIST */
export const fetchMyConsults = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.MyConsults.list)
            .then((response) => {
                const MyConsults = formatMyConsults(response.data);
                dispatch({
                    type: SET_MYCONSULT_LIST,
                    payload: keyBy(MyConsults, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// MYCONSULTS
export const createMyConsult = (newMyConsult) => {
    return async (dispatch) => {
        dispatch({ type: MYCONSULT_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.MyConsults.create, newMyConsult)
            .then((response) => {
                const MyConsult = formatMyConsults(response.data);
                dispatch({ type: ADD_NEW_MYCONSULT, payload: MyConsult });
                dispatch({ type: MYCONSULT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Mi Consulta creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: MYCONSULT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateMyConsult = (updatedMyConsult) => {
    return async (dispatch) => {
        dispatch({ type: MYCONSULT_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.MyConsults.edit}${updatedMyConsult && updatedMyConsult._id}`, updatedMyConsult)
            .then((response) => {
                const MyConsult = formatMyConsults(response.data);
                dispatch({ type: UPDATE_MYCONSULT, payload: MyConsult });
                dispatch({ type: MYCONSULT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Mi Consulta actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: MYCONSULT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteMyConsults = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: MYCONSULT_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.MyConsults.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_MYCONSULT, payload: Ids });
                dispatch({ type: MYCONSULT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Mi Consulta eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: MYCONSULT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
