import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_ROOMS,
    DELETE_ROOMS,
    ROOMS_FORM_TOOGLE_LOADING,
    SET_ROOMS_LIST,
    SHOW_NOTIFICATION,
    UPDATE_ROOMS,
} from "../constants";
import { formatroom } from "./settingsActionsUtils";

/* room LIST */
export const fetchRooms = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.room.list)
            .then((response) => {
                const room = formatroom(response.data);
                dispatch({
                    type: SET_ROOMS_LIST,
                    payload: keyBy(room, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// room
export const createRooms = (newroom) => {
    return async (dispatch) => {
        dispatch({ type: ROOMS_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.room.create, newroom)
            .then((response) => {
                const room = formatroom(response.data);
                dispatch({ type: ADD_NEW_ROOMS, payload: room });
                dispatch({ type: ROOMS_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Sala creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: ROOMS_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateRooms = (updatedroom) => {
    return async (dispatch) => {
        dispatch({ type: ROOMS_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.room.edit}${updatedroom && updatedroom._id}`, updatedroom)
            .then((response) => {
                const room = formatroom(response.data);
                dispatch({ type: UPDATE_ROOMS, payload: room });
                dispatch({ type: ROOMS_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Sala actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: ROOMS_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteRooms = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: ROOMS_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.room.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_ROOMS, payload: Ids });
                dispatch({ type: ROOMS_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Sala eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: ROOMS_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
