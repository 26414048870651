import {
  HomeOutlined, LoginOutlined, LogoutOutlined, UserOutlined, AuditOutlined,
  CalendarOutlined, HistoryOutlined, TeamOutlined, ReadOutlined, HourglassOutlined,
  ContactsOutlined, ContainerOutlined, DatabaseOutlined, NotificationOutlined
} from '@ant-design/icons';
import React from 'react';
import { ROLES } from "./permissions";

export const routes = {
  home: {
    key: "home",
    slug: "home",
    to: "/home",
    title: "Dashboard",
    icon: <HomeOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role, ROLES.user.role, ROLES.employee.role],
  },

  profile: {
    key: "profile",
    slug: "profile",
    to: "/profile",
    title: "Perfil",
    icon: <UserOutlined />,
    showInMenu: false,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role, ROLES.user.role, ROLES.employee.role],
  },

  login: {
    key: "login",
    slug: "login",
    to: "/login",
    title: "LOGIN",
    icon: <LoginOutlined />,
    showInMenu: false,
    showHeader: false,
    showSearch: false,
    action: null,
    role: [ROLES.admin.role, ROLES.user.role, ROLES.employee.role],
  },

  medicalAppointments: {
    key: "medicalAppointments",
    slug: "medicalAppointments",
    to: "/medicalAppointments",
    title: "Pedir Citas",
    icon: <CalendarOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role, ROLES.user.role],
  },

  medicalConsultations: {
    key: "medicalConsultations",
    slug: "medicalConsultations",
    to: "/medicalConsultations",
    title: "Consultas Médicas",
    icon: <AuditOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },

  room: {
    key: "room",
    slug: "room",
    to: "/room",
    title: "Salas",
    icon: <DatabaseOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },

  MyConsults: {
    key: "MyConsults",
    slug: "MyConsults",
    to: "/MyConsults",
    title: "Mis Consultas",
    icon: <ReadOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role, ROLES.employee.role, ROLES.user.role],
  },

  timeToConsultations: {
    key: "timeToConsultations",
    slug: "timeToConsultations",
    to: "/timeToConsultations",
    title: "Tiempo Espera",
    icon: <HourglassOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role, ROLES.user.role],
  },

  typeMedicalConsultations: {
    key: "typeMedicalConsultations",
    slug: "typeMedicalConsultations",
    to: "/typeMedicalConsultations",
    title: "Tipo Consultas",
    icon: <ContainerOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role, ROLES.employee.role],
  },

  consultsNotifications: {
    key: "consultsNotifications",
    slug: "consultsNotifications",
    to: "/consultsNotifications",
    title: "Notificaciones",
    icon: <NotificationOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },

  MedicalHistorys: {
    key: "MedicalHistorys",
    slug: "MedicalHistorys",
    to: "/MedicalHistorys",
    title: "Historiales Médicos",
    icon: <HistoryOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },

  employees: {
    key: "employees",
    slug: "employees",
    to: "/employees",
    title: "Trabajadores",
    icon: <TeamOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },

  clients: {
    key: "clients",
    slug: "clients",
    to: "/clients",
    title: "Clientes",
    icon: <ContactsOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },

  users: {
    key: "users",
    slug: "users",
    to: "/users",
    title: "Usuarios",
    icon: <UserOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },

  logout: {
    key: "logout",
    slug: "logout",
    to: "/logout",
    title: "Cerrar Sesión",
    icon: <LogoutOutlined />,
    showInMenu: false,
    showHeader: false,
    showSearch: false,
    action: null,
    role: [ROLES.admin.role, ROLES.user.role],
  },
};
