import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_MEDICALAPPOINTMENT,
    DELETE_MEDICALAPPOINTMENT,
    MEDICALAPPOINTMENT_FORM_TOOGLE_LOADING,
    SET_MEDICALAPPOINTMENT_LIST,
    SHOW_NOTIFICATION,
    UPDATE_MEDICALAPPOINTMENT,
} from "../constants";
import { formatMedicalAppointments } from "./settingsActionsUtils";

/* MEDICALAPPOINTMENT LIST */
export const fetchMedicalAppointments = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.medicalAppointments.list)
            .then((response) => {
                const medicalAppointments = formatMedicalAppointments(response.data);
                dispatch({
                    type: SET_MEDICALAPPOINTMENT_LIST,
                    payload: keyBy(medicalAppointments, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// MEDICALAPPOINTMENTS
export const createMedicalAppointment = (newMedicalAppointment) => {
    return async (dispatch) => {
        dispatch({ type: MEDICALAPPOINTMENT_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.medicalAppointments.create, newMedicalAppointment)
            .then((response) => {
                const medicalAppointment = formatMedicalAppointments(response.data);
                dispatch({ type: ADD_NEW_MEDICALAPPOINTMENT, payload: medicalAppointment });
                dispatch({ type: MEDICALAPPOINTMENT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Pedir Cita creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: MEDICALAPPOINTMENT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateMedicalAppointment = (updatedMedicalAppointment) => {
    return async (dispatch) => {
        dispatch({ type: MEDICALAPPOINTMENT_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.medicalAppointments.edit}${updatedMedicalAppointment && updatedMedicalAppointment._id}`, updatedMedicalAppointment)
            .then((response) => {
                const medicalAppointment = formatMedicalAppointments(response.data);
                dispatch({ type: UPDATE_MEDICALAPPOINTMENT, payload: medicalAppointment });
                dispatch({ type: MEDICALAPPOINTMENT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Pedir Cita actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: MEDICALAPPOINTMENT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteMedicalAppointments = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: MEDICALAPPOINTMENT_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.medicalAppointments.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_MEDICALAPPOINTMENT, payload: Ids });
                dispatch({ type: MEDICALAPPOINTMENT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Pedir Cita eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: MEDICALAPPOINTMENT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
